import { Button, LinearProgress, Menu, MenuItem, Stack, styled, Typography } from '@mui/material';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import { StorageServices } from 'services/storage';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  AddIcon,
  BackIcon,
  GroupUsersIcon,
  MenuIcon,
  NavItemsIcon,
  // UserIcon,
  CheckIcon
} from 'assets/icons';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import { Helmet } from 'react-helmet-async';

type SideMenu = {
  label: string;
  link: string;
  renderIcon?: (props: React.SVGProps<SVGSVGElement>) => void;
  hidden?: boolean;
  paths?: string[];
  items?: SideMenu[];
  role?: string[];
};

type LayoutProps = {
  children: ReactNode;
};

const colorStyle = {
  color: '#49454F',
  width: '24px',
  height: '24px'
};

const storage = new StorageServices();

export const LayoutAdmin = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { loading: callbackLoading } = useLoading();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const role = storage.getRole();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  const MENU: SideMenu[] = useMemo(() => {
    return [
      {
        renderIcon: (props: React.SVGProps<SVGSVGElement>) => <GroupUsersIcon {...props} />,
        label: 'Enterprise Accounts',
        link: '/enterprise-accounts',
        role: ['enterprise', 'admin', 'subEnterprise']
      },
      // {
      //   renderIcon: (props: React.SVGProps<SVGSVGElement>) => <UserIcon {...props} />,
      //   label: 'Individual Accounts',
      //   link: '/individual-accounts',
      //   role: ['individual', 'admin']
      // },
      {
        renderIcon: (props: React.SVGProps<SVGSVGElement>) => <AddIcon {...props} />,
        label: 'New Account',
        link: '/new-account',
        role: ['admin']
      }
    ];
  }, []);
  const ADMIN_MENU: SideMenu[] = useMemo(() => {
    return [
      {
        renderIcon: (props: React.SVGProps<SVGSVGElement>) => <CheckIcon {...props} />,
        label: 'Admin Accounts',
        link: '/admin-accounts',
        role: ['admin']
      },
      {
        renderIcon: (props: React.SVGProps<SVGSVGElement>) => <GroupUsersIcon {...props} />,
        label: 'Reports',
        link: '/reports',
        role: ['admin']
      },
      {
        renderIcon: (props: React.SVGProps<SVGSVGElement>) => <NavItemsIcon {...props} />,
        label: 'App users',
        link: '/users',
        role: ['admin']
      },
      {
        renderIcon: () => <AnalyticsIcon />,
        label: 'Activity Logs',
        link: '/logs',
        role: ['admin']
      }
    ];
  }, []);

  const handleLogout = useCallback(() => {
    navigate('/login');
    storage.removeAccessToken();
    storage.removeRefreshToken();
  }, [storage]);
  return (
    <Stack minHeight={'100vh'} flexDirection={'row'} width={'100%'} position={'relative'}>
      <Helmet>
        <title>Vidatalk - Management</title>
      </Helmet>
      {callbackLoading && <LinearLoading color="success" />}
      <WrapperNavbar iscollapse={`${isCollapse}`}>
        <Stack alignItems={'center'}>
          {isCollapse ? (
            <MenuIcon
              style={{ color: 'black', width: 40, height: 40 }}
              onClick={() => setIsCollapse(false)}
            />
          ) : (
            <BackIcon
              style={{ color: 'black', width: 24, height: 24 }}
              onClick={() => setIsCollapse(true)}
            />
          )}
          {MENU.map((item, index) => {
            if (item.hidden || (item.role && role && !item.role?.includes(role))) return;
            return (
              <NavLink style={{ width: '100%' }} to={item.link} key={index}>
                <WrapperItem className={'button-navbar'} gap={'14px'} iscollapse={`${isCollapse}`}>
                  <>{item?.renderIcon && item.renderIcon({ style: colorStyle })}</>
                  <TextStyled className={'text-menu'}>{item.label}</TextStyled>
                </WrapperItem>
              </NavLink>
            );
          })}
        </Stack>
        <div>
          {role === 'admin'
            ? ADMIN_MENU.map((item, index) => {
                if (item.hidden || (item.role && role && !item.role?.includes(role))) return;
                return (
                  <NavLink style={{ width: '100%' }} to={item.link} key={index}>
                    <WrapperItem
                      className={'button-navbar'}
                      gap={'14px'}
                      iscollapse={`${isCollapse}`}>
                      <>{item?.renderIcon && item.renderIcon({ style: colorStyle })}</>
                      <TextStyled className={'text-menu'}>{item.label}</TextStyled>
                    </WrapperItem>
                  </NavLink>
                );
              })
            : null}
        </div>
      </WrapperNavbar>
      <RightContainer iscollapse={`${isCollapse}`}>
        <WrapperHeader>
          <img
            style={{ cursor: 'pointer', padding: `12px 0 0 12px` }}
            alt={'img'}
            width={264}
            src={'/images/logo.png'}
            onClick={() => navigate('/')}
          />
          <WrapperRightHeader>
            <WrapperAvatar
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <AccountCircleIcon sx={{ width: '20px', height: ' 20px', color: '#1C1B1F' }} />
            </WrapperAvatar>
            <MenuStyled
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem style={{ gap: '10px' }} onClick={() => navigate('/my-profile')}>
                <Typography style={{ fontWeight: 500 }}>My Profile</Typography>
              </MenuItem>
              <MenuItem style={{ gap: '10px' }} onClick={handleLogout}>
                <LogoutIcon />
                <Typography style={{ fontWeight: 500 }}>Logout</Typography>
              </MenuItem>
            </MenuStyled>
          </WrapperRightHeader>
        </WrapperHeader>
        <ScrollStyled>{children}</ScrollStyled>
      </RightContainer>
    </Stack>
  );
};

const MenuStyled = styled(Menu)(() => ({
  '.MuiPaper-root': {
    minWidth: 130,
    top: '53px !important',
    right: '32px !important',
    bottom: 'unset !important',
    left: 'unset !important'
  }
}));

const LinearLoading = styled(LinearProgress)(() => ({
  position: 'fixed',
  width: '100vw',
  zIndex: 1000
}));
const WrapperNavbar = styled(Stack)(({ iscollapse }: { iscollapse: string }) => ({
  width: iscollapse === 'true' ? 80 : 200,
  padding: '40px 10px',
  background: '#FEF7FF',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const WrapperItem = styled(Stack)(({ iscollapse }: { iscollapse: string }) => ({
  width: '100%',
  flexDirection: iscollapse === 'true' ? 'column' : 'row',
  gap: iscollapse === 'true' ? '4px' : '8px',
  padding: '12px 0',
  alignItems: 'center'
}));
const TextStyled = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  textAlign: 'center'
}));
const WrapperHeader = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '70px',
  flexDirection: 'row',
  padding: '0 30px 0 0',
  marginBottom: 12
}));
const ScrollStyled = styled(Stack)(() => ({
  flexGrow: 1,
  overflowY: 'auto',
  background: '#fff',
  width: '100%',
  height: 'calc(100vh - 82px)'
}));
const WrapperRightHeader = styled(Stack)(() => ({
  gap: 20,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row'
}));
const WrapperAvatar = styled(Button)(() => ({
  borderRadius: 22
}));
const RightContainer = styled(Stack)(({ iscollapse }: { iscollapse: string }) => ({
  width: iscollapse === 'true' ? 'calc(100% - 80px)' : 'calc(100% - 200px)'
}));
