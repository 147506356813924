import React, { useEffect } from 'react';
import { Box, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { UpdateProfileForm, useInjection } from './hooks';
import { InputText } from 'app/components/elements/InputText';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProfileSchema } from 'utils/validate';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';

export const MyProfilePage = () => {
  const { profile, updateProfile, defaultForm } = useInjection();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
    setValue
  } = useForm<UpdateProfileForm>({
    resolver: yupResolver(updateProfileSchema),
    defaultValues: defaultForm
  });

  useEffect(() => {
    if (!defaultForm.email) return;
    reset(defaultForm);
  }, [JSON.stringify(defaultForm)]);
  return (
    <Wrapper>
      <TitleStyled>My Profile</TitleStyled>
      <form onSubmit={handleSubmit(updateProfile)}>
        <Grid mt={1} container spacing={{ xs: 1, sm: 2, xl: 3 }}>
          <Grid sm={12} md={4} xl={3}>
            <InputText
              placeholder={'Name'}
              inputError={errors.name?.message}
              inputProps={{
                ...register('name')
              }}
            />
          </Grid>
          <Grid sm={12} md={4} xl={3}>
            <InputText
              placeholder={'Email'}
              inputError={errors.email?.message}
              inputProps={{
                ...register('email')
              }}
              onChange={(e) => {
                clearErrors('email');
                setValue('email', e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid mt={1} container spacing={{ xs: 1, sm: 2, xl: 3 }}>
          {profile?.organization && (
            <Grid sm={12} md={4} xl={3}>
              <InputText
                placeholder={'Account'}
                inputProps={{
                  value: `${profile?.organization?.name} (${profile?.organization?.id})`,
                  disabled: true
                }}
              />
            </Grid>
          )}
          <Grid sm={12} md={4} xl={3}>
            <InputText
              placeholder={'Password'}
              inputError={errors.password?.message}
              inputProps={{
                ...register('password'),
                type: 'password'
              }}
              onChange={(e) => {
                clearErrors('password');
                setValue('password', e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid mt={1} container spacing={{ xs: 1, sm: 2, xl: 3 }}>
          <Grid sm={12} md={8} xl={6} display={'flex'} direction="row" justifyContent="flex-end">
            <ButtonCustom type="submit" style={{ width: '20%' }} variant={'outlined'}>
              Save
            </ButtonCustom>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  padding: 32,
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)'
}));
const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
