import { useCallback, useEffect, useState } from 'react';
import { useFlash } from 'contexts/flash';
import { createOrganization, getContractDetail, updateContract } from 'services/admin';
import { OrganizationForm } from 'app/components/modules/CreateOrganizationForm';
import { useOrganizations } from 'contexts/organizations';
import { Contract } from 'types/organization';
import { StorageServices } from 'services/storage';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { requestResetPassword } from 'services/organization';

export type ContractForm = {
  contractEndDate: string;
  limitLicense: number;
};

const storage = new StorageServices();
export const useInjection = () => {
  const { setFlash } = useFlash();
  const role = storage.getRole();
  const { selectedOrganization } = useOrganizations();
  const [openNewAccount, setOpenNewAccount] = useState<boolean>(false);
  const [contract, setContract] = useState<Contract | null>(null);

  const fetchContract = useCallback(async () => {
    if (!selectedOrganization || !role || role !== 'admin') return;
    const data = await getContractDetail(selectedOrganization.id);
    setContract(data);
  }, [selectedOrganization, role]);

  const handleCreateOrganization = useCallback(
    async (formData: OrganizationForm) => {
      await createOrganization({
        organization: {
          name: formData.organizationName,
          limitLicense: formData.limitLicense,
          parentOrganizationId: selectedOrganization?.id
        },
        account: {
          username: formData.username,
          password: formData.password
        },
        organizationUser: {
          email: formData.email,
          name: formData.adminName
        }
      });
      setFlash({ type: 'success', message: 'Create organization successfully!' });
      setOpenNewAccount(false);
    },
    [selectedOrganization]
  );

  const handleUpdateContract = useCallback(
    async (formData: ContractForm) => {
      if (!selectedOrganization) return;
      await updateContract(selectedOrganization.id, formData);
      setFlash({ type: 'success', message: 'Update organization successfully!' });
    },
    [selectedOrganization]
  );

  const { asyncCallback: handleRequestResetPass } = useAsyncCallback(async () => {
    if (!selectedOrganization) return;
    await requestResetPassword(selectedOrganization.id);
    setFlash({ type: 'success', message: 'Password Request Successful!' });
  }, [selectedOrganization]);

  useEffect(() => {
    fetchContract();
  }, [selectedOrganization]);
  return {
    role,
    selectedOrganization,
    contract,
    openNewAccount,
    setOpenNewAccount,
    handleCreateOrganization,
    handleUpdateContract,
    handleRequestResetPass,
    defaultForm: {
      limitLicense: contract?.limitLicense,
      contractEndDate: contract?.contractEndDate
    }
  };
};
