import { ChangeEvent, useRef } from 'react';
import { Stack, styled, Typography } from '@mui/material';

type DropFileInputProps = {
  onFileChange: (file: File) => void;
};
export const DropFileInput = ({ onFileChange }: DropFileInputProps) => {
  const wrapperRef = useRef(null);

  const onFileDrop = (e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      onFileChange(files[0]);
    }
  };

  return (
    <DragWrapper ref={wrapperRef} className="drop-file-input">
      <Typography textAlign="center">Drag and Drop Here</Typography>
      <Typography textAlign="center">OR</Typography>
      <Typography textAlign="center" color={'blue'}>
        Browse File
      </Typography>
      <Input type="file" value="" onChange={onFileDrop} />
    </DragWrapper>
  );
};

const DragWrapper = styled(Stack)(() => ({
  position: 'relative',
  borderRadius: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '200px',
  backgroundColor: '#ECE6F0',
  ':hover': {
    opacity: 0.6
  }
}));

const Input = styled('input')(() => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer'
}));
