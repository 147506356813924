import React from 'react';
import { CreateOrganizationForm } from 'app/components/modules/CreateOrganizationForm';
import { useInjection } from './hooks';
import { Stack, styled, Typography } from '@mui/material';
import { InputSelect } from 'app/components/elements/InputSelect';

export const NewAccountPage = () => {
  const { handleCreateOrganization, setSelectedOrganization, organizations, selectedOrganization } =
    useInjection();
  return (
    <Wrapper>
      <>
        <Typography fontWeight="bold">Link to Master Account</Typography>
        <StyledSelect
          title={'Master accounts'}
          options={organizations}
          value={selectedOrganization}
          onChange={(e) => setSelectedOrganization(e.target.value as number)}
        />
      </>
      <CreateOrganizationForm onSubmit={handleCreateOrganization} />
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  marginLeft: 32,
  width: '50%',
  maxWidth: '588px',
  backgroundColor: '#ECE6F0',
  borderRadius: '28px',
  padding: 24,
  gap: 16
}));

const StyledSelect = styled(InputSelect)(() => ({
  minHeight: 56,
  maxWidth: 540,
  '& .MuiInputBase-input': {
    backgroundColor: '#E6E0E9',
    padding: '16px 12px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    borderRadius: 10
  }
}));
