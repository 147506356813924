import React, { Fragment, useEffect } from 'react';
import { Box, styled, Stack, Typography } from '@mui/material';
import { AdminForm, useInjection } from './hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminSchema } from 'utils/validate';
import Grid from '@mui/material/Unstable_Grid2';
import { FilledInput } from 'app/components/elements/InputText';
import get from 'lodash/get';
import { AddIcon, TrashIcon } from 'assets/icons';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';

export const AdminsPage = () => {
  const { defaultForm, onSave } = useInjection();
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<{ admins: AdminForm }>({
    resolver: yupResolver(adminSchema),
    defaultValues: defaultForm
  });
  const {
    fields: admins,
    append,
    remove
  } = useFieldArray({
    control,
    keyName: 'rowId',
    name: 'admins'
  });

  useEffect(() => {
    if (!defaultForm.admins.length) return;
    reset(defaultForm);
  }, [JSON.stringify(defaultForm)]);
  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Stack direction={'row'} sx={{ overflowY: 'hidden', height: 'inherit' }}>
        <Wrapper>
          <TitleStyled>VidaTalk Admins List</TitleStyled>
          <DescriptionStyled>
            List of accounts with Super Admin and Super Admin (Restricted) rights.
          </DescriptionStyled>
          <Stack direction={'column'} py={4}>
            <TableHeaders container>
              <Grid xs={1} />
              <Grid xs={5}>
                <HeaderColum>Name</HeaderColum>
              </Grid>
              <Grid xs={6}>
                <HeaderColum>Email</HeaderColum>
              </Grid>
            </TableHeaders>
            {admins.map((admin, index) => (
              <Fragment key={admin.rowId}>
                <Grid container pt={'8px'} py={'8px'}>
                  <Grid xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <TrashIcon onClick={() => remove(index)} />
                  </Grid>
                  <Grid xs={5} pr={2}>
                    <FilledInput
                      inputProps={register(`admins.${index}.name`)}
                      inputError={(get(errors, `admins.${index}.name.message`) as string) || ''}
                      variant={'filled'}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <FilledInput
                      inputProps={register(`admins.${index}.email`)}
                      inputError={(get(errors, `admins.${index}.email.message`) as string) || ''}
                      variant={'filled'}
                      disabled={!!admin?.id}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ))}
            <TableHeaders container style={{ padding: '24px 0' }}>
              <Grid xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <AddIcon
                  onClick={() => {
                    append({ name: '', email: '' });
                  }}
                />
              </Grid>
            </TableHeaders>
          </Stack>
          <Stack alignItems={'flex-end'} pt={2} px={1} direction={'row-reverse'}>
            <ButtonCustom variant={'contained'} style={{ width: '15%' }} type="submit">
              Save
            </ButtonCustom>
            <ButtonCustom style={{ width: '15%' }}>Cancel</ButtonCustom>
          </Stack>
        </Wrapper>
      </Stack>
    </form>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  padding: 32,
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)'
}));
const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
const DescriptionStyled = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '22px',
  fontStyle: 'italic'
}));

const TableHeaders = styled(Grid)(() => ({
  borderBottom: '1px solid #CAC4D0',
  paddingBottom: 8
}));

const HeaderColum = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#999999'
}));
