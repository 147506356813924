import { useCallback, useEffect, useState } from 'react';
import { OrganizationForm } from 'app/components/modules/CreateOrganizationForm';
import { createOrganization, listOrganization } from 'services/admin';
import { useFlash } from 'contexts/flash';
import { Organization } from 'types/organization';
import { useNavigate } from 'react-router-dom';

type Option = {
  value?: string | number;
  label: string;
};
export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [selectedOrganization, setSelectedOrganization] = useState<number | undefined>();
  const [organizations, setOrganizations] = useState<Option[]>([]);
  useEffect(() => {
    fetchOrganizations();
  }, []);
  const fetchOrganizations = useCallback(async (page = 1) => {
    const { organizations } = await listOrganization({
      isMasterOrganization: true,
      page,
      size: 1000
    });
    const raw = organizations.map((item: Organization) => ({
      value: item.id,
      label: `${item.name} (${item.id})`
    }));
    raw.unshift({ label: `Select a master account` });
    setOrganizations(raw);
  }, []);
  const handleCreateOrganization = useCallback(
    async (formData: OrganizationForm) => {
      await createOrganization({
        organization: {
          name: formData.organizationName,
          limitLicense: formData.limitLicense,
          parentOrganizationId: selectedOrganization
        },
        account: {
          username: formData.username,
          password: formData.password
        },
        organizationUser: {
          email: formData.email,
          name: formData.adminName
        }
      });
      setFlash({ type: 'success', message: 'Create organization successfully!' });
      navigate('/enterprise-accounts');
    },
    [selectedOrganization]
  );
  return { handleCreateOrganization, setSelectedOrganization, selectedOrganization, organizations };
};
