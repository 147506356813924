import axios from 'axios';
import { OrganizationUser } from 'types/organization';

export const listMyOrganizations = async () => {
  const { data } = await axios.get('/organizations/mine');
  return data;
};

export const listUsersOfOrganizations = async (
  id: number
): Promise<{ users: OrganizationUser[] }> => {
  const { data } = await axios.get(`/organizations/${id}/users`);
  return data;
};

export const updateMyOrganization = async (id: number, payload: unknown) => {
  const { data } = await axios.put(`/organizations/${id}/settings`, payload);
  return data;
};

export const updateSecurityOrganization = async (payload: unknown) => {
  const { data } = await axios.put(`/organizations/security`, { securities: payload });
  return data;
};

export const uploadQrCode = async (id: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.put(`/organizations/${id}/qr-code`, formData);
  return data;
};

export const requestResetPassword = async (id: number) => {
  const { data } = await axios.post(`/organizations/request-password`, { id });
  return data;
};
