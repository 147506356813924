import axios from 'axios';
import { UpdateUserPayload } from 'types/user';

export const loginWithAdmin = async (payload: { email: string; password: string }) => {
  const { data } = await axios.post('/auth/admins/signin', payload);
  return data;
};

export const loginWithEnterprise = async (payload: { email: string; password: string }) => {
  const { data } = await axios.post('/auth/signin', payload);
  return data;
};

export const getUserInfo = async () => {
  const { data } = await axios.get('/auth/me');
  return data;
};

export const updateUserInfo = async (payload: UpdateUserPayload) => {
  const { data } = await axios.put('/auth/me', payload);
  return data;
};

export const updateAdminInfo = async (payload: UpdateUserPayload) => {
  const { data } = await axios.put('/auth/admins/me', payload);
  return data;
};

export const getAdminProfile = async () => {
  const { data } = await axios.get(`/auth/admins/me`);
  return data;
};
