import { useLocation, useNavigate } from 'react-router-dom';
import { useFlash } from 'contexts/flash';
import { useCallback, useState } from 'react';
import { FormLoginType } from './components/FormLogin';
import { loginWithAdmin, loginWithEnterprise } from 'services/auth';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();

export const useInjection = () => {
  const location = useLocation();
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [loginRole, setLoginRole] = useState<string>('admin');
  const postLogin = useCallback(
    async ({ account, password }: FormLoginType) => {
      const payload = { email: account, password };
      const res = await (loginRole === 'admin'
        ? loginWithAdmin(payload)
        : loginWithEnterprise(payload));
      setFlash({ type: 'success', message: 'You are successfully logged in' });
      storage.setAccessToken(res?.accessToken);
      storage.setRefreshToken(res?.refreshToken);
      storage.setRole(res?.role);
      navigate('/');
      // eslint-disable-next-line
        }, [location, loginRole]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(postLogin, [loginRole]);
  return {
    handleSubmitForm,
    loginRole,
    setLoginRole
  };
};
