import { Stack, styled, Typography, Box } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type LayoutContentProps = {
  title?: string;
  children: ReactNode;
  textFooter?: string;
  textLink?: string;
  textNavigation?: string;
  textUnderlineNone?: boolean;
};
export const LayoutContent = ({
  title,
  children,
  textNavigation,
  textFooter,
  textLink,
  textUnderlineNone
}: LayoutContentProps) => {
  return (
    <Stack
      pt={3.375}
      pb={2.75}
      width={'100%'}
      alignItems={'center'}
      minHeight={'100vh'}
      justifyContent={'space-between'}>
      <Stack
        px={{ xs: 3.125, sm: 0 }}
        mt={3}
        width={'100%'}
        maxWidth={{ xs: 'unset', sm: 390 }}
        pb={4}>
        <Stack width={'100%'} alignItems={'center'}>
          <img alt={'logo'} width={264} src={'/images/logo.png'} />
        </Stack>
        <Stack width={'100%'} alignItems={'center'}>
          <Typography fontSize={'26px'} lineHeight={'33px'} fontWeight={700} color={'#22374A'}>
            {title}
          </Typography>
        </Stack>
        <WrapperChild>{children}</WrapperChild>
      </Stack>
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Typography color="#22374A">{textFooter}</Typography>
        <Link
          to={textNavigation || ''}
          style={{ color: '#22374A', textDecoration: textUnderlineNone ? 'none' : 'underline' }}>
          {textLink}
        </Link>
      </Box>
    </Stack>
  );
};

const WrapperChild = styled(Stack)(() => ({
  width: '100%'
}));
