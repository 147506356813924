import React, { useState } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { UsersList } from './UsersList';
import { OrganizationUsersList } from './OrganizationUsersList';

export const UsersPage = () => {
  const [activeTab, setActiveTab] = useState('individual');
  return (
    <>
      <Stack direction={'row'} sx={{ overflowY: 'hidden', height: 'inherit' }}>
        <Wrapper>
          <TitleStyled>VidaTalk User List</TitleStyled>
          <DescriptionStyled>
            Full list of users with access to VidaTalk, including individual, admin, and enterprise
            account users
          </DescriptionStyled>
          <Stack direction={'row'} gap={1} py={3}>
            <MenuItem
              key={'individual-tab'}
              active={`${activeTab === 'individual'}`}
              onClick={() => setActiveTab('individual')}>
              <b>Individual Users</b>
            </MenuItem>
            <MenuItem
              key={'enterprise-tab'}
              active={`${activeTab === 'enterprise'}`}
              onClick={() => setActiveTab('enterprise')}>
              <b>Enterprise account users</b>
            </MenuItem>
          </Stack>
          {activeTab === 'individual' ? <UsersList /> : <OrganizationUsersList />}
        </Wrapper>
      </Stack>
    </>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  padding: 32,
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)'
}));
const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
const DescriptionStyled = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '22px',
  fontStyle: 'italic'
}));
const MenuItem = styled(Box)(({ active }: { active: string }) => ({
  cursor: 'pointer',
  padding: 8,
  borderRadius: 8,
  background: active === 'true' ? '#F5F5F5' : '#FFFFFF'
}));
