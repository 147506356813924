import React from 'react';
import { Stack } from '@mui/material';
import { ForgotForm } from 'app/pages/ForgotPasswordPage/components/ForgotForm';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { Helmet } from 'react-helmet-async';
import { useInjection } from './hook';

export const ForgotPasswordPage = () => {
  const { handleSubmitForm } = useInjection();

  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Vidatalk - Forgot password</title>
      </Helmet>
      <LayoutContent
        title={'Forgot password?'}
        children={<ForgotForm onSubmit={handleSubmitForm} />}
      />
    </Stack>
  );
};
