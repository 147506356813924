import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { listUsersOfOrganizations, updateSecurityOrganization } from 'services/organization';
import { useEffect, useMemo, useState } from 'react';
import { useOrganizations } from 'contexts/organizations';
import { compact } from 'lodash';
import { useFlash } from 'contexts/flash';

type Account = {
  id?: number;
  email: string;
  name?: string;
  role?: string;
  organizationId?: number;
};

export type SecurityForm = Account[];

export const useInjection = () => {
  const { setFlash } = useFlash();
  const { selectedOrganization } = useOrganizations();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { asyncCallback } = useAsyncCallback(async () => {
    if (!selectedOrganization) return;
    const { users } = await listUsersOfOrganizations(selectedOrganization?.id);
    setAccounts(
      users.map((item) => ({
        id: item.id,
        email: item.email,
        name: item.name,
        role: item.role,
        organizationId: item.organizationId
      }))
    );
  }, [selectedOrganization]);
  useEffect(() => {
    asyncCallback();
  }, [selectedOrganization]);
  const { asyncCallback: onSave } = useAsyncCallback(
    async (formData: { accounts: SecurityForm }) => {
      const deletedAdmins = accounts.map((account) => {
        if (formData.accounts.every((item) => item.id !== account.id))
          return {
            ...account,
            id: account.id,
            isDelete: true
          };
      });
      const payload = [...formData.accounts, ...compact(deletedAdmins)];
      await updateSecurityOrganization(payload);
      await asyncCallback();
      setFlash({ type: 'success', message: 'Update admins successfully!' });
    },
    [accounts]
  );

  return {
    onSave,
    defaultForm: {
      accounts
    },
    selectedOrganization,
    subOrganizations: selectedOrganization?.childrenOrganizations?.map((item) => ({
      value: item.id,
      label: item.name || ''
    })),
    isSubAccount: useMemo(
      () => !!selectedOrganization?.parentOrganizationId,
      [selectedOrganization]
    )
  };
};
