import React, { useMemo } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useOrganizations } from 'contexts/organizations';
import { useSearchParams } from 'react-router-dom';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();

type AccountControlPanelsProps = {
  defaultTab?: string;
};

const TABS = [
  {
    name: 'Account',
    path: 'account',
    role: ['enterprise', 'admin', 'subEnterprise']
  },
  {
    name: 'Settings',
    path: 'settings',
    role: ['admin']
  },
  {
    name: 'Security',
    path: 'security',
    role: ['enterprise', 'admin', 'subEnterprise']
  },
  {
    name: 'Reports',
    path: 'reports',
    role: ['enterprise', 'admin', 'subEnterprise']
  },
  {
    name: 'QR Code',
    path: 'qr-code',
    role: ['enterprise', 'admin', 'subEnterprise']
  }
];
function AccountControlPanels({ defaultTab }: AccountControlPanelsProps) {
  const { selectedOrganization } = useOrganizations();
  const [params, setUrlParams] = useSearchParams();
  const tab = params.get('tab') || defaultTab;
  const role = storage.getRole();
  const authorizeTabs = useMemo(() => {
    if (!role) return [];
    return TABS.filter((item) => item.role.includes(role));
  }, [role]);
  return (
    <>
      <OrganizeTitleStyled>
        {selectedOrganization?.name} ({selectedOrganization?.id})
      </OrganizeTitleStyled>
      <Stack direction={'row'} gap={1} pb={3}>
        {authorizeTabs.map((item, index) => (
          <MenuItem
            key={index}
            active={`${tab === item.path}`}
            onClick={() =>
              setUrlParams({
                tab: item.path
              })
            }>
            {item.name}
          </MenuItem>
        ))}
      </Stack>
    </>
  );
}

export default AccountControlPanels;
const OrganizeTitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
const MenuItem = styled(Box)(({ active }: { active: string }) => ({
  cursor: 'pointer',
  padding: 8,
  borderRadius: 8,
  background: active === 'true' ? '#F5F5F5' : '#FFFFFF'
}));
