import { useEffect, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { listAdmins, updateAdmins } from 'services/admin';
import { compact } from 'lodash';
import { useFlash } from 'contexts/flash';

export type AdminForm = {
  id?: number;
  email: string;
  name?: string;
}[];
export const useInjection = () => {
  const [admins, setAdmins] = useState<AdminForm>([]);
  const { setFlash } = useFlash();

  const { asyncCallback } = useAsyncCallback(async () => {
    const res = await listAdmins();
    setAdmins(res);
  }, []);

  const { asyncCallback: onSave } = useAsyncCallback(
    async (formData: { admins: AdminForm }) => {
      const deletedAdmins = admins.map((admin) => {
        if (formData.admins.every((item) => item.id !== admin.id))
          return {
            id: admin.id,
            isDelete: true
          };
      });
      const payload = [...formData.admins, ...compact(deletedAdmins)];
      await updateAdmins(payload);
      setFlash({ type: 'success', message: 'Update admins successfully!' });
    },
    [admins]
  );

  useEffect(() => {
    asyncCallback();
  }, []);
  return {
    admins,
    onSave,
    defaultForm: {
      admins
    }
  };
};
