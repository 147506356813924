import { useEffect, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { StorageServices } from 'services/storage';
import { getAdminProfile, getUserInfo, updateAdminInfo, updateUserInfo } from 'services/auth';
import { useFlash } from 'contexts/flash';

type Profile = {
  id: number;
  name: string;
  email: string;
  role: string;
  organization?: {
    id: number;
    name?: number;
  };
};

export type UpdateProfileForm = {
  email: string;
  name?: string;
  password: string;
};
const storage = new StorageServices();
export const useInjection = () => {
  const { setFlash } = useFlash();

  const role = storage.getRole();
  const [profile, setProfile] = useState<Profile | null>(null);
  const { asyncCallback: fetchProfile } = useAsyncCallback(async () => {
    if (!role) return;
    if (role === 'admin') {
      const res = await getAdminProfile();
      setProfile(res);
      return;
    }
    const res = await getUserInfo();
    setProfile(res);
  }, [role]);
  const { asyncCallback: updateProfile } = useAsyncCallback(
    async (formData: UpdateProfileForm) => {
      const payload = {
        name: formData.name,
        email: formData.email,
        password: formData.password === 'default_password' ? undefined : formData.password
      };
      if (!role) return;
      if (role === 'admin') {
        await updateAdminInfo(payload);
      } else {
        await updateUserInfo(payload);
      }
      setFlash({ type: 'success', message: 'Updated profile successfully!' });
    },
    [role]
  );
  useEffect(() => {
    fetchProfile();
  }, [role]);
  return {
    defaultForm: {
      name: profile?.name,
      email: profile?.email,
      password: 'default_password'
    },
    profile,
    updateProfile
  };
};
