import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { PortalDialog } from 'app/components/modules/PortalDialog';
import { useInjection } from './hooks';
import { DropFileInput } from './DropFile';

export const QRCode = () => {
  const {
    openModal,
    selectedOrganization,
    setOpenModal,
    file,
    onChangeFile,
    handleUploadQR,
    loadingCb,
    url
  } = useInjection();
  return (
    <div>
      <Heading>Manage the external account QR code displayed in the VidaTalk app.</Heading>
      <ReplaceButton onClick={() => setOpenModal(true)}>Replace QR Code</ReplaceButton>
      <Stack justifyContent={'center'} alignItems={'center'}>
        {selectedOrganization && (
          <img style={{ width: 358, height: 358 }} src={url} alt="qr-code" />
        )}
      </Stack>
      <PortalDialog
        title={'Upload New QR Code'}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}>
        <Stack width={540} gap={2}>
          <UploadHelperText>Upload a new QR code to replace the existing QR code.</UploadHelperText>
          <DropFileInput onFileChange={onChangeFile} />
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography textAlign="center" pb={2}>
              {file ? `${file.name}  ${Math.floor(file.size / 1024)}kB` : ''}
            </Typography>
            <ButtonCustom
              type="button"
              style={{ width: '20%' }}
              variant={'outlined'}
              loading={loadingCb}
              onClick={handleUploadQR}>
              Upload
            </ButtonCustom>
          </Stack>
        </Stack>
      </PortalDialog>
    </div>
  );
};

const Heading = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
  color: '#000000'
}));

const ReplaceButton = styled(ButtonCustom)(() => ({
  width: 'unset',
  padding: 'unset'
}));

const UploadHelperText = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000000'
}));
