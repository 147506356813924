import React from 'react';
import { Stack, styled } from '@mui/material';
import { PAGE_SIZE, useInjection } from './hooks';
import { DataGrid } from '@mui/x-data-grid';
import { FilledInput } from 'app/components/elements/InputText';
import InputAdornment from '@mui/material/InputAdornment';
import { ExportIcon, MenuIcon, SearchIcon } from 'assets/icons';

export const OrganizationUsersList = () => {
  const {
    users,
    columns,
    total,
    page,
    onChangePage,
    loading,
    keyword,
    onChangeKeyword,
    onSearch,
    onExportUsers,
    setSelectedUser
  } = useInjection();
  return (
    <>
      <Stack direction={'row'} py={2} alignItems={'center'} justifyContent={'space-between'}>
        <SearchInput
          value={keyword}
          onChange={(event) => onChangeKeyword(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onSearch();
            }
          }}
          placeholder={'Search'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MenuIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={onSearch} style={{ cursor: 'pointer' }} />
              </InputAdornment>
            )
          }}
        />
        <ExportIcon style={{ cursor: 'pointer' }} onClick={onExportUsers} />
      </Stack>
      <Stack direction={'column'}>
        <DataGrid
          rows={users}
          columns={columns}
          rowCount={total}
          paginationModel={{
            page: page ? Number(page) - 1 : 1,
            pageSize: PAGE_SIZE
          }}
          onPaginationModelChange={(newPaginationModel) =>
            onChangePage(newPaginationModel.page + 1)
          }
          onRowClick={({ row }) => setSelectedUser(row)}
          loading={loading}
          checkboxSelection={false}
          disableAutosize
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableColumnSorting
          disableDensitySelector
          paginationMode="server"
          sx={{ border: 0 }}
        />
      </Stack>
    </>
  );
};

const SearchInput = styled(FilledInput)(() => ({
  width: 360,
  borderRadius: '28px',
  border: 'none',
  backgroundColor: '#E6E0E9',
  color: '#1D1B20',
  '& .MuiInputBase-input': {
    borderRadius: '28px',
    border: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '28px',
    border: 'none'
  }
}));
