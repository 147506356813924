import React, { useCallback, useMemo } from 'react';
import { Box, Stack, styled } from '@mui/material';
import AccountList from 'app/components/modules/AccountList';
import { Reports } from 'app/components/modules/ControlPanels/Reports';
import AccountControlPanels from 'app/components/modules/AccountControlPanels';
import { useSearchParams } from 'react-router-dom';
import { Account } from 'app/components/modules/ControlPanels/Account';
import { Settings } from 'app/components/modules/ControlPanels/Settings';
import { StorageServices } from 'services/storage';
import { Security } from 'app/components/modules/ControlPanels/Security';
import { QRCode } from 'app/components/modules/ControlPanels/QRCode';
const storage = new StorageServices();

export const ReportPage = () => {
  const [params] = useSearchParams();
  const role = storage.getRole();

  const tab = useMemo(() => {
    return params.get('tab') || 'reports';
  }, [params, role]);

  const renderTab = useCallback(() => {
    if (!role) return;
    switch (tab) {
      case 'account':
        return <Account />;
      case 'settings':
        return role === 'admin' ? <Settings /> : <></>;
      case 'security':
        return <Security />;
      case 'reports':
        return <Reports />;
      case 'qr-code':
        return <QRCode />;
    }
  }, [tab, role]);
  return (
    <Stack direction={'row'} sx={{ overflowY: 'hidden' }}>
      <AccountList />
      <Wrapper>
        <AccountControlPanels defaultTab={'reports'} />
        {renderTab()}
      </Wrapper>
    </Stack>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  width: 'calc(100% - 314px)',
  padding: 32,
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)'
}));
