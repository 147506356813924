import { useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { listOrganizationUsers } from 'services/admin';
import { OrganizationUser } from 'types/user';
import { GridColDef } from '@mui/x-data-grid';
import { saveVideoFile } from 'utils/downloadFile';
export const PAGE_SIZE = 10;
const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    width: 250
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 160,
    valueGetter: (value, row) =>
      row.role === 'enterprise' ? 'Enterprise Admin' : 'Sub Account admin'
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
    valueGetter: (value, row) => (row.status === 1 ? 'Active' : 'Inactive')
  },
  {
    field: 'organization',
    headerName: 'Organization',
    width: 160,
    valueGetter: (value, row) => row.organization?.name
  }
];
export const useInjection = () => {
  const [users, setUsers] = useState<OrganizationUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>('');

  const { asyncCallback, loadingCb } = useAsyncCallback(
    async (query?: { page?: number; keyword?: string; size?: number }) => {
      const res = await listOrganizationUsers({ size: PAGE_SIZE, ...query });
      setTotal(res.total);
      setUsers(res.users);
    },
    []
  );

  const onChangePage = useCallback((page: number | string) => {
    setPage(Number(page));
    asyncCallback({ page });
  }, []);

  const onSearch = useCallback(() => {
    setPage(1);
    asyncCallback({ page: 1, keyword });
  }, [keyword]);

  const onChangeKeyword = useCallback(
    (val: string) => {
      setKeyword(val);
    },
    [keyword]
  );

  const onExportUsers = useCallback(async () => {
    await saveVideoFile(`/admins/users/export?keyword=${keyword}`, 'vidatalk-users.xlsx');
  }, [keyword]);

  useEffect(() => {
    asyncCallback();
  }, []);
  return {
    users,
    columns,
    total,
    page,
    onChangePage,
    loading: loadingCb,
    keyword,
    onChangeKeyword,
    onSearch,
    onExportUsers,
    selectedUser,
    setSelectedUser
  };
};
