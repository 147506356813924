import React from 'react';
import { Box, styled, Typography } from '@mui/material';

export const LogsPage = () => {
  return (
    <Box p={4}>
      <TitleStyled>VidaTalk Activity Logs</TitleStyled>
      <a
        href="https://analytics.416inc.net"
        target={'_blank'}
        style={{ color: 'blue', textDecoration: 'underline' }}>
        Click here to view analytic logs
      </a>
    </Box>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
